import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path:'',
    redirectTo:'loader',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./pages/Startseite/team.module').then( m => m.TeamPageModule)
  },
  {
    path: 'infobutton',
    loadChildren: () => import('./pages/eigene Geräte/infobutton.module').then( m => m.InfobuttonPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/Login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'einstellung',
    loadChildren: () => import('./pages/einstellung/einstellung.module').then( m => m.EinstellungPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./pages/impressum/impressum.module').then( m => m.ImpressumPageModule)
  },
  {
    path: 'registrieren',
    loadChildren: () => import('./pages/registrieren/registrieren.module').then( m => m.RegistrierenPageModule)
  },
  {
    path: 'waschmaschine',
    loadChildren: () => import('./geraete/waschmaschine/waschmaschine.module').then( m => m.WaschmaschinePageModule)
  },
  {
    path: 'mikrowelle',
    loadChildren: () => import('./geraete/dunstabzugshaube/mikrowelle.module').then( m => m.MikrowellePageModule)
  },
  {
    path: 'ofen',
    loadChildren: () => import('./geraete/ofen/ofen.module').then( m => m.OfenPageModule)
  },
  {
    path: 'geschirrspueler',
    loadChildren: () => import('./geraete/geschirrspueler/geschirrspueler.module').then( m => m.GeschirrspuelerPageModule)
  },
  {
    path: 'waschtrockner',
    loadChildren: () => import('./geraete/waschtrockner/waschtrockner.module').then( m => m.WaschtrocknerPageModule)
  },
  {
    path: 'fernseher',
    loadChildren: () => import('./geraete/fernseher/fernseher.module').then( m => m.FernseherPageModule)
  },
  {
    path: 'kuehlschrank',
    loadChildren: () => import('./geraete/kuehlschrank/kuehlschrank.module').then( m => m.KuehlschrankPageModule)
  },
  {
    path: 'lichtquelle',
    loadChildren: () => import('./geraete/lichtquelle/lichtquelle.module').then( m => m.LichtquellePageModule)
  },
  {
    path: 'waeschetrockner',
    loadChildren: () => import('./geraete/waeschetrockner/waeschetrockner.module').then( m => m.WaeschetrocknerPageModule)
  },
  {
    path: 'warmwasserbereiter',
    loadChildren: () => import('./geraete/warmwasserbereiter/warmwasserbereiter.module').then( m => m.WarmwasserbereiterPageModule)
  },
  {
    path: 'luftkonditionierer',
    loadChildren: () => import('./geraete/luftkonditionierer/luftkonditionierer.module').then( m => m.LuftkonditioniererPageModule)
  },
  {
    path: 'einzelraumheizgeraete',
    loadChildren: () => import('./geraete/einzelraumheizgeraete/einzelraumheizgeraete.module').then( m => m.EinzelraumheizgeraetePageModule)
  },
  {
    path: 'rezessionen',
    loadChildren: () => import('./pages/rezessionen/rezessionen.module').then( m => m.RezessionenPageModule)
  },
  {
    path: 'passwortvergessen',
    loadChildren: () => import('./pages/passwortvergessen/passwortvergessen.module').then( m => m.PasswortvergessenPageModule)
  },
  {
    path: 'bewertung/:id',
    loadChildren: () => import('./pages/bewertung/bewertung.module').then( m => m.BewertungPageModule)
  },
  {
    path: 'detailseite/:id',
    loadChildren: () => import('./pages/detailseite/detailseite.module').then( m => m.DetailseitePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
