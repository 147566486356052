/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder} from '@angular/forms';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Startseite', url: '/team', icon: 'man' },
    { title: 'eigene Geräte', url: '/infobutton', icon: 'flower' },
    { title: 'Rezessionen', url: '/rezessionen', icon: 'pencil' },
    { title: 'Einstellung', url: '/einstellung', icon: 'flower' },
  ];


  constructor(private router: Router, private fb: FormBuilder) { }
}

export class myGlobalData {
  static gUserName = 'nicht angemeldet';
  static gUserEMail = 'Sie sind nicht angemeldet';
}
